import { render, staticRenderFns } from "./form.vue?vue&type=template&id=049c131e&scoped=true&"
import script from "./form.vue?vue&type=script&lang=js&"
export * from "./form.vue?vue&type=script&lang=js&"
import style0 from "./form.vue?vue&type=style&index=0&id=049c131e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049c131e",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CDivider: require('@chakra-ui/vue').CDivider, CFormLabel: require('@chakra-ui/vue').CFormLabel, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CFormControl: require('@chakra-ui/vue').CFormControl, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CGrid: require('@chakra-ui/vue').CGrid})
