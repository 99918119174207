<template>
  <c-box>
    <c-box
      v-for="(item, index) in data"
      :key="index"
      position="relative"
    >
      <c-form-control
        is-required
        mb="25px"
        :is-invalid="invalid?.$each[index]?.position?.$invalid"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Posisi Bekerja {{ index + 1 }}
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="item.position"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Posisi Bekerja"
            height="62px"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control
        is-required
        mb="25px"
        :is-invalid="invalid?.$each[index]?.companyName?.$invalid"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Nama Perusahaan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="item.companyName"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Pengalaman Bekerja"
            height="62px"
          />
        </c-input-group>
      </c-form-control>
      <c-flex align="center">
        <c-form-control
          is-required
          mb="12px"
          :style="{ width: '50%', marginRight: '20px' }"
          :is-invalid="invalid?.$each[index]?.startYear?.$invalid"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Tahun Masuk
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="item.startYear"
              type="number"
              error-border-color="red.300"
              placeholder="Masukkan Tahun Masuk"
              height="62px"
            />
          </c-input-group>
        </c-form-control>
        <c-form-control
          is-required
          mb="12px"
          :style="{ width: '50%' }"
          :is-disabled="item.isCurrentlyWork"
          :is-invalid="invalid?.$each[index]?.endYear?.$invalid"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Tahun Keluar
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="item.endYear"
              type="number"
              error-border-color="red.300"
              placeholder="Masukkan Tahun Keluar"
              height="62px"
            />
          </c-input-group>
        </c-form-control>
      </c-flex>

      <c-flex
        justify="space-between"
        align="center"
        mb="25px"
        mx="8px"
      >
        <c-flex align="center">
          <c-checkbox 
            v-model="item.isCurrentlyWork" 
            size="md"
            variant-color="primary"
          >
            <c-text
              font-size="14px"
              color="#888"
            >
              Saya masih bekerja di sini
            </c-text>
          </c-checkbox>
        </c-flex>
        <c-button
          v-if="data.length > 1"
          ml="18px"
          min-width="auto"
          variant-color="red"
          variant="link"
          font-size="14px"
          @click="onClickRemove(index)"
        >
          <c-image
            :src="require('@/assets/icon-trash-line.svg')"
            alt="empty"
            mr="4px"
            width="20px"
            height="20px"
          />
          Hapus Pengalaman {{ index + 1 }}
        </c-button>
      </c-flex>
    </c-box>
    <c-button
      :is-disabled="isEmpty"
      variant-color="primary"
      variant="outline"
      border-radius="60px"
      h="62px"
      w="100%"
      left-icon="add"
      @click="$emit('add')"
    >
      Tambah Pengalaman Kerja
    </c-button>

    <ModalConfirm
      :title="`Anda yakin ingin hapus pengalaman bekerja ${selectedIndex+1}?`"
      :image="require('@/assets/icon-confirm-delete.svg')"
      :is-open="isOpenConfirm"
      :is-loading="false"
      submit-text="Hapus"
      @close="onCloseConfirm"
      @submit="onSubmitConfim"
    />
  </c-box>
</template>

<script>
import ModalConfirm from '@/components/widgets/modal-confirm'

export default {
  name: 'FormExperience',
  components: { ModalConfirm },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['remove', 'add'],
  data() {
    return {
      isOpenConfirm: false,
      selectedIndex: null,
    }
  },
  methods: {
    onClickRemove(index) {
      this.selectedIndex = index
      this.isOpenConfirm = true
    },
    onCloseConfirm() {
      this.isOpenConfirm = false
      this.selectedIndex = null
    },
    async onSubmitConfim() {
      await this.$emit('remove', this.selectedIndex)
      this.onCloseConfirm()
    },
  },
}
</script>
