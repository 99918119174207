var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm._l(_vm.data, function (item, index) {
    var _vm$invalid, _vm$invalid$$each$ind, _vm$invalid$$each$ind2, _vm$invalid2, _vm$invalid2$$each$in, _vm$invalid2$$each$in2;

    return _c('c-flex', {
      key: index,
      attrs: {
        "position": "relative",
        "align-items": "center"
      }
    }, [_c('c-grid', {
      attrs: {
        "flex-grow": "1",
        "template-columns": "repeat(2, 1fr)",
        "gap": "1rem"
      }
    }, [_c('FormTime', {
      attrs: {
        "label": 'Jam Aktif ' + (index + 1),
        "invalid": (_vm$invalid = _vm.invalid) === null || _vm$invalid === void 0 ? void 0 : (_vm$invalid$$each$ind = _vm$invalid.$each[index]) === null || _vm$invalid$$each$ind === void 0 ? void 0 : (_vm$invalid$$each$ind2 = _vm$invalid$$each$ind.startHour) === null || _vm$invalid$$each$ind2 === void 0 ? void 0 : _vm$invalid$$each$ind2.$invalid
      },
      model: {
        value: item.startHour,
        callback: function callback($$v) {
          _vm.$set(item, "startHour", $$v);
        },
        expression: "item.startHour"
      }
    }), _c('FormTime', {
      attrs: {
        "label": 'Jam Selesai ' + (index + 1),
        "invalid": (_vm$invalid2 = _vm.invalid) === null || _vm$invalid2 === void 0 ? void 0 : (_vm$invalid2$$each$in = _vm$invalid2.$each[index]) === null || _vm$invalid2$$each$in === void 0 ? void 0 : (_vm$invalid2$$each$in2 = _vm$invalid2$$each$in.endHour) === null || _vm$invalid2$$each$in2 === void 0 ? void 0 : _vm$invalid2$$each$in2.$invalid
      },
      model: {
        value: item.endHour,
        callback: function callback($$v) {
          _vm.$set(item, "endHour", $$v);
        },
        expression: "item.endHour"
      }
    })], 1), _vm.data.length > 1 ? _c('c-button', {
      attrs: {
        "flex-shrink": "0",
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link",
        "font-size": "14px"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickRemove(index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty",
        "mr": "4px",
        "width": "30px",
        "height": "30px"
      }
    })], 1) : _vm._e()], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.isEmpty,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add",
      "mb": "25px"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add');
      }
    }
  }, [_vm._v(" Tambah Jam Aktif Chat ")]), _c('ModalConfirm', {
    attrs: {
      "title": "Anda yakin ingin hapus jam aktif chat ".concat(_vm.selectedIndex + 1, "?"),
      "image": require('@/assets/icon-confirm-delete.svg'),
      "is-open": _vm.isOpenConfirm,
      "is-loading": false,
      "submit-text": "Hapus"
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": _vm.onSubmitConfim
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }