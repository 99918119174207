<template>
  <c-form-control
    is-required
    mb="25px"
  >
    <c-form-label
      font-size="14px"
      color="#555555"
      font-weigh="400"
      font-family="Roboto"
    >
      {{ label }}
    </c-form-label>
    <c-input-group
      size="md"
      z-index="1"
    >
      <vue-timepicker 
        v-model="value_"
        v-chakra="{
          'input.display-time': {
            p: '1rem !important',
            height: ['48px !important', '62px !important'],
            fontFamily: 'Roboto',
            fontWeight: '500',
            borderRadius: '4px',
            border: '1px solid #CBD5E0',
            fontSize: '18px',
          }
        }"
        format="HH:mm"
        :minute-interval="1" 
        hour-label="Jam"
        minute-label="Menit"
        input-width="100%"
        :close-on-complete="true"
        :hide-clear-button="false" 
        drop-direction="up"
        container-id="startSeviceHour"
        :input-class="['skip-error-style', invalid ? 'invalid' : '']"
        :font-size="['14px', '18px']"
        :color="value ? '#008C81' : '#555'"
        fixed-dropdown-button 
        lazy
      >
        <template #dropdownButton>
          <c-flex align="center">
            <c-text
              mr="1rem"
              font-size="18px"
            >
              WIB
            </c-text>
            <inline-svg
              :src="require('@/assets/icons/icon-clock.svg')"
              height="30px"
              width="30px"
              fill="#888"
            />
          </c-flex>
        </template>
      </vue-timepicker>
    </c-input-group>
  </c-form-control>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'FormTime',
  components: { VueTimepicker },
  props: ['value', 'invalid', 'label'],
  computed: {
    value_: {
      get: function() {
        return this.value
      },
      set(val) {
        if (val.includes(':mm')) {
          const value = val.slice(0, 2) + ':00'
          this.$emit('input', value)
        } else { 
          this.$emit('input', val)
        }
      },
    },
  }, 
}
</script>

<style scoped>
::v-deep.vue__time-picker-dropdown, ::v-deep.vue__time-picker .dropdown {
  bottom: 4em !important;
}

::v-deep.vue__time-picker input.invalid {
  box-shadow:  0 0 0 1px #e66673 !important;
  border: 1px solid #e66673 !important;
}

::v-deep.vue__time-picker .controls img,
::v-deep.vue__time-picker .controls svg {
  max-width: 35px !important;
  margin-right: 12px !important;
}

::v-deep.vue__time-picker input.display-time {
  font-size: 18px !important;
}

@media (min-width: 768px) {
  ::v-deep.vue__time-picker-dropdown, ::v-deep.vue__time-picker .dropdown {
    bottom: 4em !important;
  }
}
</style>