var render = function () {
  var _vm$textValue;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          background: '#FFF'
        }
      },
      expression: "{\n      ':hover': {\n        background: '#FFF',\n      },\n    }"
    }],
    attrs: {
      "position": "relative",
      "w": "100%",
      "background-color": "#FFF",
      "color": _vm.textValue ? '#111' : '#BFBFBFDE',
      "text-align": "left",
      "border-radius": "0.25rem",
      "border-style": "solid",
      "border-width": "1px",
      "border-color": _vm.isInvalid ? '#e66673' : '',
      "outline": "2px solid transparent",
      "outline-offset": "2px",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "h": "62px",
      "px": "1rem",
      "line-height": "2.5rem",
      "font-weight": "400",
      "mb": "25px",
      "box-shadow": _vm.isInvalid ? '0 0 0 1px #e66673' : '',
      "as": "button",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onToogle.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$textValue = _vm.textValue) !== null && _vm$textValue !== void 0 ? _vm$textValue : _vm.placeholder) + " "), _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "fill": "#888",
      "width": "15px",
      "height": "15px"
    }
  })], 1), _vm.isOpen ? _c('c-box', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.onClose,
      expression: "onClose"
    }],
    attrs: {
      "pos": "absolute",
      "bg": "#FFF",
      "top": "60px",
      "w": "100%",
      "border-bottom": "1px solid #E2E8F0",
      "border-left": "1px solid #E2E8F0",
      "border-right": "1px solid #E2E8F0",
      "border-bottom-radius": "8px",
      "z-index": "2"
    }
  }, [_c('c-checkbox-group', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':not(:last-child)': {
          borderBottom: '1px solid #333'
        }
      },
      expression: "{\n        ':not(:last-child)': {\n          borderBottom: '1px solid #333',\n        },\n      }"
    }],
    attrs: {
      "variant-color": "primary",
      "spacing": "0px",
      "size": "lg"
    },
    model: {
      value: _vm.value_,
      callback: function callback($$v) {
        _vm.value_ = $$v;
      },
      expression: "value_"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c('c-checkbox', {
      key: item.value,
      attrs: {
        "value": item.value,
        "d": "flex",
        "flex-flow": "row-reverse",
        "justify-content": "space-between",
        "p": "10px 20px",
        "mb": "0",
        "border-top-style": "solid",
        "border-top-width": "1px",
        "border-top-color": "#E2E8F0",
        "is-disabled": _vm.isFilterOptions ? _vm.isOptionAlreadyChecked(item.value) : false
      }
    }, [_c('c-text', {
      attrs: {
        "color": _vm.isAlreadyChecked(item.value) ? 'primary.400' : '#111',
        "font-size": "18px",
        "font-weight": _vm.isAlreadyChecked(item.value) ? 500 : 400
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")])], 1);
  }), 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }