var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-input-group', {
    attrs: {
      "size": "md",
      "z-index": "1"
    }
  }, [_c('vue-timepicker', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input.display-time': {
          p: '1rem !important',
          height: ['48px !important', '62px !important'],
          fontFamily: 'Roboto',
          fontWeight: '500',
          borderRadius: '4px',
          border: '1px solid #CBD5E0',
          fontSize: '18px'
        }
      },
      expression: "{\n        'input.display-time': {\n          p: '1rem !important',\n          height: ['48px !important', '62px !important'],\n          fontFamily: 'Roboto',\n          fontWeight: '500',\n          borderRadius: '4px',\n          border: '1px solid #CBD5E0',\n          fontSize: '18px',\n        }\n      }"
    }],
    attrs: {
      "format": "HH:mm",
      "minute-interval": 1,
      "hour-label": "Jam",
      "minute-label": "Menit",
      "input-width": "100%",
      "close-on-complete": true,
      "hide-clear-button": false,
      "drop-direction": "up",
      "container-id": "startSeviceHour",
      "input-class": ['skip-error-style', _vm.invalid ? 'invalid' : ''],
      "font-size": ['14px', '18px'],
      "color": _vm.value ? '#008C81' : '#555',
      "fixed-dropdown-button": "",
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "dropdownButton",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "align": "center"
          }
        }, [_c('c-text', {
          attrs: {
            "mr": "1rem",
            "font-size": "18px"
          }
        }, [_vm._v(" WIB ")]), _c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-clock.svg'),
            "height": "30px",
            "width": "30px",
            "fill": "#888"
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.value_,
      callback: function callback($$v) {
        _vm.value_ = $$v;
      },
      expression: "value_"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }