var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "0",
      "pb": "20px",
      "paths": _vm.breadcrumbPath
    }
  }), _c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mb": ['0', '16px'],
      "p": ['1rem', '2rem 4rem'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "mb": "30px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Edit" : "Tambah") + " Ahli Gizi ")]), _vm.id ? _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "mr": "20px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    },
    on: {
      "click": _vm.onOpenDelete
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Hapus ")], 1) : _vm._e()], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Data Pribadi ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-w": "800px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Foto Ahli Gizi ")]), _c('c-flex', [_c('label', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        bg: 'white',
        cursor: 'pointer',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '50%',
        w: '180px',
        h: '180px',
        d: 'flex',
        p: '0',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      },
      expression: "{\n              bg: 'white',\n              cursor: 'pointer',\n              border: '1px solid #C4C4C4',\n              boxSizing: 'border-box',\n              borderRadius: '50%',\n              w: '180px',\n              h: '180px',\n              d: 'flex',\n              p: '0',\n              alignItems: 'center',\n              justifyContent: 'center',\n              overflow: 'hidden',\n            }"
    }],
    attrs: {
      "for": "img-preview"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPreviewImage(_vm.photoUrl),
      "w": !_vm.getPreviewImage(_vm.photoUrl).includes('icon-photo') ? '100%' : '94px',
      "h": !_vm.getPreviewImage(_vm.photoUrl).includes('icon-photo') ? '100%' : '94px',
      "alt": "img program",
      "object-fit": "cover",
      "mx": "auto",
      "border-radius": "6px"
    }
  })], 1), _c('input', {
    ref: "photo",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "img-preview",
      "type": "file",
      "accept": ".jpg, .jpeg, .png"
    },
    on: {
      "change": function change($event) {
        return _vm.onChangePhoto($event, 'preview');
      }
    }
  }), _c('c-box', {
    attrs: {
      "mt": "2rem",
      "ml": "1rem"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "border-radius": "25px",
      "flex-direction": "row",
      "align-items": "center",
      "h": "42px",
      "mb": "8px",
      "font-family": "Roboto",
      "font-size": "16px"
    },
    on: {
      "click": function click($event) {
        _vm.$refs.photo.value = '';

        _vm.$refs.photo.click();
      }
    }
  }, [_c('c-box', {
    attrs: {
      "mr": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-upload.svg'),
      "height": "22px",
      "width": "22px",
      "fill": "#008C81"
    }
  })], 1), _vm._v(" Upload Gambar ")], 1), _c('c-text', {
    attrs: {
      "px": "32px",
      "color": "#C4C4C4"
    }
  }, [_vm._v(" Foto max: 2MB ")])], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.$v.email.$invalid || _vm.errors.email != '',
      "is-required": "",
      "mb": "25px",
      "is-read-only": _vm.id ? true : false
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Email ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "email",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Email",
      "height": "62px"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _vm.errors.email ? _c('c-form-helper-text', {
    attrs: {
      "font-size": "14px",
      "color": "red.500"
    }
  }, [_vm._v(" " + _vm._s(_vm.errors.email) + " ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.$v.str.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nomor STR ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nomor STR",
      "height": "62px"
    },
    model: {
      value: _vm.str,
      callback: function callback($$v) {
        _vm.str = $$v;
      },
      expression: "str"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.$v.firstName.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nama Depan",
      "height": "62px"
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nama Belakang",
      "height": "62px"
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  })], 1)], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Spesialisasi / Keahlian ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.$v.specialization.$invalid
    }
  }, [_c('FormSpecialization', {
    attrs: {
      "data": _vm.specialization,
      "options": _vm.SPECIALIZATIONS,
      "is-empty": _vm.isEmptySpecialization,
      "invalid": _vm.$v.specialization,
      "other-value": _vm.otherOptionValue
    },
    on: {
      "add": _vm.addSpecialization,
      "remove": _vm.removeSpecialization
    }
  })], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Bahasa yang dikuasai ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormLanguages', {
    attrs: {
      "data": _vm.languages,
      "is-empty": _vm.isEmptyLanguages
    },
    on: {
      "add": _vm.addLanguages,
      "remove": _vm.removeLanguages
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Usia Klien ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormClientAgeHandled', {
    attrs: {
      "data": _vm.clientAgeHandled,
      "is-empty": _vm.isEmptyClientAgeHandled
    },
    on: {
      "add": _vm.addClientAgeHandled,
      "remove": _vm.removeClientAgeHandled
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Masalah yang dikuasai ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormProblemHandled', {
    attrs: {
      "data": _vm.problemHandled,
      "is-empty": _vm.isEmptyProblemHandled
    },
    on: {
      "add": _vm.addProblemHandled,
      "remove": _vm.removeProblemHandled
    }
  }), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.$v.quota.$invalid,
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kuota ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Quota",
      "height": "62px"
    },
    model: {
      value: _vm.quota,
      callback: function callback($$v) {
        _vm.quota = $$v;
      },
      expression: "quota"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.$v.rating.$invalid,
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Rating ")]), _c('c-input-group', {
    attrs: {
      "size": "md",
      "mt": "10px"
    }
  }, [_c('StarRating', {
    attrs: {
      "increment": 0.5,
      "star-size": 32,
      "text-class": "hidden"
    },
    model: {
      value: _vm.rating,
      callback: function callback($$v) {
        _vm.rating = $$v;
      },
      expression: "rating"
    }
  })], 1)], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Pendidikan ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormEducation', {
    attrs: {
      "data": _vm.education,
      "is-empty": _vm.isEmptyEducation,
      "degre": _vm.degre,
      "universities": _vm.dataUniversities,
      "invalid": _vm.$v.education
    },
    on: {
      "add": _vm.addEducation,
      "remove": _vm.removeEducation,
      "other": function other(it) {
        return _vm.onSetOtherUniversity(it.event, it.index);
      }
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jam Layanan ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('c-grid', {
    attrs: {
      "template-columns": "repeat(2, 1fr)",
      "gap": "1rem"
    }
  }, [_c('FormTime', {
    attrs: {
      "invalid": _vm.$v.serviceHour.start.$invalid,
      "label": "Jam Mulai Layanan"
    },
    model: {
      value: _vm.serviceHour.start,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceHour, "start", $$v);
      },
      expression: "serviceHour.start"
    }
  }), _c('FormTime', {
    attrs: {
      "invalid": _vm.$v.serviceHour.end.$invalid,
      "label": "Jam Selesai Layanan"
    },
    model: {
      value: _vm.serviceHour.end,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceHour, "end", $$v);
      },
      expression: "serviceHour.end"
    }
  })], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jam Aktif Chat ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormActiveHour', {
    attrs: {
      "data": _vm.activeHour,
      "is-empty": _vm.isEmptyActiveHour,
      "invalid": _vm.$v.activeHour
    },
    on: {
      "add": _vm.addActiveHour,
      "remove": _vm.removeActiveHour
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal Layanan ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Hari Aktif ")]), _c('FormSelectDays2', {
    attrs: {
      "options": _vm.DAYS,
      "is-invalid": _vm.$v.activeDays.$invalid,
      "is-filter-options": true,
      "options-already-selected": _vm.offDays.concat(_vm.slowResponseDays)
    },
    model: {
      value: _vm.activeDays,
      callback: function callback($$v) {
        _vm.activeDays = $$v;
      },
      expression: "activeDays"
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Hari Libur ")]), _c('FormSelectDays2', {
    attrs: {
      "options": _vm.DAYS,
      "is-invalid": _vm.$v.offDays.$invalid,
      "is-filter-options": true,
      "options-already-selected": _vm.activeDays.concat(_vm.slowResponseDays)
    },
    model: {
      value: _vm.offDays,
      callback: function callback($$v) {
        _vm.offDays = $$v;
      },
      expression: "offDays"
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Hari Slowrespon ")]), _c('FormSelectDays2', {
    attrs: {
      "options": _vm.DAYS,
      "is-invalid": _vm.$v.slowResponseDays.$invalid,
      "is-filter-options": true,
      "options-already-selected": _vm.activeDays.concat(_vm.offDays)
    },
    model: {
      value: _vm.slowResponseDays,
      callback: function callback($$v) {
        _vm.slowResponseDays = $$v;
      },
      expression: "slowResponseDays"
    }
  })], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Pengalaman Bekerja ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormExperience', {
    attrs: {
      "data": _vm.workExperiences,
      "is-empty": _vm.isEmptyworkExperiences,
      "invalid": _vm.$v.workExperiences
    },
    on: {
      "add": _vm.addWorkExperience,
      "remove": _vm.removeWorkExperience
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "gray",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px",
      "color": "#555555"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "font-size": "18px",
      "is-disabled": _vm.isDisabledSubmit
    },
    on: {
      "click": _vm.onOpenSave
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1), _c('ModalConfirm', {
    attrs: {
      "title": "Anda yakin ingin hapus data ahli gizi?",
      "image": require('@/assets/icon-confirm-delete.svg'),
      "is-open": _vm.isOpenDelete,
      "is-loading": _vm.isLoading,
      "submit-text": "Hapus"
    },
    on: {
      "close": _vm.onCloseDelete,
      "submit": _vm.onSubmitDelete
    }
  }), _c('ModalConfirm', {
    attrs: {
      "title": _vm.id ? 'Apakah anda yakin ingin melakukan perubahan data ahli gizi?' : 'Apakah anda yakin data ahli gizi sudah benar?',
      "image": require('@/assets/icon-confirm-update.svg'),
      "is-open": _vm.isOpenSave,
      "is-loading": _vm.isLoading,
      "submit-text": _vm.id ? 'Simpan' : 'Tambah'
    },
    on: {
      "close": _vm.onCloseSave,
      "submit": _vm.onSubmit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }