<template>
  <c-box pos="relative">
    <c-box
      v-chakra="{
        ':hover': {
          background: '#FFF',
        },
      }"
      position="relative"
      w="100%"
      background-color="#FFF"
      :color="textValue ? '#111' : '#BFBFBFDE'"
      text-align="left"
      border-radius="0.25rem"
      border-style="solid"
      border-width="1px"
      :border-color="isInvalid ? '#e66673' : ''"
      outline="2px solid transparent"
      outline-offset="2px"
      display="flex"
      align-items="center"
      justify-content="space-between"
      h="62px"
      px="1rem"
      line-height="2.5rem"
      font-weight="400"
      mb="25px"
      :box-shadow="isInvalid ? '0 0 0 1px #e66673' : ''"
      as="button"
      type="button"
      @click.prevent="onToogle"
    >
      {{ textValue ?? placeholder }}
      <inline-svg
        :src="require('@/assets/icons/icon-arrow-down.svg')"
        fill="#888"
        width="15px"
        height="15px"
      />
    </c-box>

    <c-box
      v-if="isOpen"
      v-on-clickaway="onClose"
      pos="absolute"
      bg="#FFF"
      top="60px"
      w="100%"
      border-bottom="1px solid #E2E8F0"
      border-left="1px solid #E2E8F0"
      border-right="1px solid #E2E8F0"
      border-bottom-radius="8px"
      z-index="2"
    >
      <c-checkbox-group
        v-model="value_"
        v-chakra="{
          ':not(:last-child)': {
            borderBottom: '1px solid #333',
          },
        }"
        variant-color="primary"
        spacing="0px"
        size="lg"
      >
        <c-checkbox
          v-for="item in options"
          :key="item.value"
          :value="item.value"
          d="flex"
          flex-flow="row-reverse"
          justify-content="space-between"
          p="10px 20px"
          mb="0"
          border-top-style="solid"
          border-top-width="1px"
          border-top-color="#E2E8F0"
          :is-disabled="isFilterOptions ? isOptionAlreadyChecked(item.value) : false"
        >
          <c-text
            :color="isAlreadyChecked(item.value) ? 'primary.400' : '#111'"
            font-size="18px"
            :font-weight="isAlreadyChecked(item.value) ? 500 : 400"
          >
            {{ item.label }}
          </c-text>
        </c-checkbox>
      </c-checkbox-group>
    </c-box>
  </c-box>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'FormSelectDay2',
  mixins: [clickaway],
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Pilih Hari',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isFilterOptions: {
      type: Boolean,
      default: false,
    },
    optionsAlreadySelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    value_: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }, 
    },
    textValue() {
      if (this.value && this.value.length > 0) {
        return this.value.join(', ')
      }
      return null
    },
  },
  methods: {
    onToogle() {
      this.isOpen = !this.isOpen
    },
    onClose() {
      this.isOpen = false
    },
    isAlreadyChecked(value) {
      if (this.value_ && this.value_.length > 0) {
        return this.value_.some((it) => it == value)
      }
      return false
    },
    isOptionAlreadyChecked(value) {
      if (this.optionsAlreadySelected.length != 0)
        return this.optionsAlreadySelected?.some((it) => it == value)
      return false
    },
  },
}
</script>
