var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm._l(_vm.data, function (item, index) {
    var _vm$invalid, _vm$invalid$$each$ind, _vm$invalid$$each$ind2, _vm$invalid2, _vm$invalid2$$each$in, _vm$invalid2$$each$in2, _vm$invalid3, _vm$invalid3$$each$in, _vm$invalid3$$each$in2, _vm$invalid4, _vm$invalid4$$each$in, _vm$invalid4$$each$in2, _vm$invalid5, _vm$invalid5$$each$in, _vm$invalid5$$each$in2;

    return _c('c-box', {
      key: 'edu' + index,
      attrs: {
        "position": "relative"
      }
    }, [_c('c-form-control', {
      attrs: {
        "is-required": "",
        "mb": "25px",
        "is-invalid": (_vm$invalid = _vm.invalid) === null || _vm$invalid === void 0 ? void 0 : (_vm$invalid$$each$ind = _vm$invalid.$each[index]) === null || _vm$invalid$$each$ind === void 0 ? void 0 : (_vm$invalid$$each$ind2 = _vm$invalid$$each$ind.level) === null || _vm$invalid$$each$ind2 === void 0 ? void 0 : _vm$invalid$$each$ind2.$invalid
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Degree ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "height": "62px",
        "placeholder": "Masukkan Level Degre"
      },
      model: {
        value: item.level,
        callback: function callback($$v) {
          _vm.$set(item, "level", $$v);
        },
        expression: "item.level"
      }
    }, _vm._l(_vm.degre, function (item_) {
      return _c('option', {
        key: item_.value,
        domProps: {
          "value": item_.value
        }
      }, [_vm._v(" " + _vm._s(item_.name) + " ")]);
    }), 0)], 1)], 1), _c('c-form-control', {
      attrs: {
        "is-required": "",
        "mb": "25px",
        "is-invalid": (_vm$invalid2 = _vm.invalid) === null || _vm$invalid2 === void 0 ? void 0 : (_vm$invalid2$$each$in = _vm$invalid2.$each[index]) === null || _vm$invalid2$$each$in === void 0 ? void 0 : (_vm$invalid2$$each$in2 = _vm$invalid2$$each$in.title) === null || _vm$invalid2$$each$in2 === void 0 ? void 0 : _vm$invalid2$$each$in2.$invalid
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Gelar ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "placeholder": "Masukkan Gelar",
        "height": "62px"
      },
      model: {
        value: item.title,
        callback: function callback($$v) {
          _vm.$set(item, "title", $$v);
        },
        expression: "item.title"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "is-required": "",
        "mb": _vm.data.length > 1 ? '12px' : '25px',
        "is-invalid": ((_vm$invalid3 = _vm.invalid) === null || _vm$invalid3 === void 0 ? void 0 : (_vm$invalid3$$each$in = _vm$invalid3.$each[index]) === null || _vm$invalid3$$each$in === void 0 ? void 0 : (_vm$invalid3$$each$in2 = _vm$invalid3$$each$in.university) === null || _vm$invalid3$$each$in2 === void 0 ? void 0 : _vm$invalid3$$each$in2.$invalid) || ((_vm$invalid4 = _vm.invalid) === null || _vm$invalid4 === void 0 ? void 0 : (_vm$invalid4$$each$in = _vm$invalid4.$each[index]) === null || _vm$invalid4$$each$in === void 0 ? void 0 : (_vm$invalid4$$each$in2 = _vm$invalid4$$each$in.otherUniversity) === null || _vm$invalid4$$each$in2 === void 0 ? void 0 : _vm$invalid4$$each$in2.$invalid)
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Universitas ")]), _c('ModelListSelect', {
      class: (_vm$invalid5 = _vm.invalid) !== null && _vm$invalid5 !== void 0 && (_vm$invalid5$$each$in = _vm$invalid5.$each[index]) !== null && _vm$invalid5$$each$in !== void 0 && (_vm$invalid5$$each$in2 = _vm$invalid5$$each$in.university) !== null && _vm$invalid5$$each$in2 !== void 0 && _vm$invalid5$$each$in2.$invalid ? 'invalid' : '',
      staticStyle: {
        "height": "62px",
        "display": "inline-flex",
        "align-items": "center",
        "font-size": "16px"
      },
      attrs: {
        "list": _vm.universities,
        "option-value": "id",
        "option-text": "name",
        "placeholder": "Masukkan Universitas"
      },
      model: {
        value: item.university,
        callback: function callback($$v) {
          _vm.$set(item, "university", $$v);
        },
        expression: "item.university"
      }
    }), item.university === '0' ? _c('c-input-group', {
      attrs: {
        "size": "md",
        "mt": "25px"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Universitas Lainnya",
        "height": "62px"
      },
      on: {
        "keyup": function keyup($event) {
          return _vm.onSetOtherUniversity($event, index);
        }
      },
      model: {
        value: item.otherUniversity,
        callback: function callback($$v) {
          _vm.$set(item, "otherUniversity", $$v);
        },
        expression: "item.otherUniversity"
      }
    })], 1) : _vm._e()], 1), _vm.data.length > 1 ? _c('c-box', {
      attrs: {
        "mb": "13px",
        "text-align": "right"
      }
    }, [_c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link",
        "font-size": "14px"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickRemove(index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty",
        "mr": "4px",
        "width": "20px",
        "height": "20px"
      }
    }), _vm._v(" Hapus Pendidikan " + _vm._s(index + 1) + " ")], 1)], 1) : _vm._e()], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.isEmpty,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add",
      "mb": "25px"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add');
      }
    }
  }, [_vm._v(" Tambah Pendidikan ")]), _c('ModalConfirm', {
    attrs: {
      "title": "Anda yakin ingin hapus pendidikan ".concat(_vm.selectedIndex + 1, "?"),
      "image": require('@/assets/icon-confirm-delete.svg'),
      "is-open": _vm.isOpenConfirm,
      "is-loading": false,
      "submit-text": "Hapus"
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": _vm.onSubmitConfim
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }