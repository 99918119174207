import { render, staticRenderFns } from "./form-education.vue?vue&type=template&id=797e1f8e&scoped=true&"
import script from "./form-education.vue?vue&type=script&lang=js&"
export * from "./form-education.vue?vue&type=script&lang=js&"
import style0 from "./form-education.vue?vue&type=style&index=0&id=797e1f8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797e1f8e",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CSelect: require('@chakra-ui/vue').CSelect, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CInput: require('@chakra-ui/vue').CInput, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox})
