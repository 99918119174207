<template>
  <c-box>
    <c-flex
      v-for="(item, index) in data"
      :key="index"
      position="relative"
      align-items="center"
    >
      <c-grid
        flex-grow="1"
        template-columns="repeat(2, 1fr)"
        gap="1rem"
      >
        <FormTime 
          v-model="item.startHour"
          :label="'Jam Aktif ' + (index + 1)"
          :invalid="invalid?.$each[index]?.startHour?.$invalid"
        />
        <FormTime 
          v-model="item.endHour"
          :label="'Jam Selesai ' + (index + 1)"
          :invalid="invalid?.$each[index]?.endHour?.$invalid"
        />
      </c-grid>
      <c-button
        v-if="data.length > 1"
        flex-shrink="0"
        ml="18px"
        min-width="auto"
        variant-color="red"
        variant="link"
        font-size="14px"
        @click="onClickRemove(index)"
      >
        <c-image
          :src="require('@/assets/icon-trash-line.svg')"
          alt="empty"
          mr="4px"
          width="30px"
          height="30px"
        />
      </c-button>
    </c-flex>
    <c-button
      :is-disabled="isEmpty"
      variant-color="primary"
      variant="outline"
      border-radius="60px"
      h="62px"
      w="100%"
      left-icon="add"
      mb="25px"
      @click="$emit('add')"
    >
      Tambah Jam Aktif Chat
    </c-button>

    <ModalConfirm
      :title="`Anda yakin ingin hapus jam aktif chat ${selectedIndex+1}?`"
      :image="require('@/assets/icon-confirm-delete.svg')"
      :is-open="isOpenConfirm"
      :is-loading="false"
      submit-text="Hapus"
      @close="onCloseConfirm"
      @submit="onSubmitConfim"
    />
  </c-box>
</template>

<script>
import FormTime from './form-time.vue'
import ModalConfirm from '@/components/widgets/modal-confirm'

export default {
  name: 'FormActiveHours',
  components: { FormTime, ModalConfirm },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['remove', 'add'],
  data() {
    return {
      isOpenConfirm: false,
      selectedIndex: null,
    }
  },
  methods: {
    onClickRemove(index) {
      this.selectedIndex = index
      this.isOpenConfirm = true
    },
    onCloseConfirm() {
      this.isOpenConfirm = false
      this.selectedIndex = null
    },
    async onSubmitConfim() {
      await this.$emit('remove', this.selectedIndex)
      this.onCloseConfirm()
    },
  },
}
</script>
