<template>
  <c-box flex-grow="1">
    <BreadcrumbPath
      px="0"
      pb="20px"
      :paths="breadcrumbPath"
    />
    <!-- <c-divider my="16px" /> -->
    <c-box
      position="relative"
      mx="auto"
      :mb="['0', '16px']"
      :p="['1rem', '2rem 4rem']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      width="100%"
    >
      <c-flex
        justify="space-between"
        align="center"
        mb="30px"
      >
        <c-heading
          as="h3"
          font-family="Roboto"
          font-weight="bold"
          font-size="24px"
          line-height="36px"
        >
          {{ id ? "Edit" : "Tambah" }} Ahli Gizi
        </c-heading>
        <c-button
          v-if="id"
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="onOpenDelete"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
      </c-flex>

      <c-heading
        as="h4"
        font-family="Montserrat"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
      >
        Data Pribadi
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />

      <form
        v-chakra
        max-w="800px"
      >
        <!-- Photo -->
        <c-form-control mb="25px">
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Foto Ahli Gizi
          </c-form-label>
          <c-flex>
            <label
              v-chakra="{
                bg: 'white',
                cursor: 'pointer',
                border: '1px solid #C4C4C4',
                boxSizing: 'border-box',
                borderRadius: '50%',
                w: '180px',
                h: '180px',
                d: 'flex',
                p: '0',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }"
              for="img-preview"
            >
              <c-image
                :src="getPreviewImage(photoUrl)"
                :w="
                  !getPreviewImage(photoUrl).includes('icon-photo')
                    ? '100%'
                    : '94px'
                "
                :h="
                  !getPreviewImage(photoUrl).includes('icon-photo')
                    ? '100%'
                    : '94px'
                "
                alt="img program"
                object-fit="cover"
                mx="auto"
                border-radius="6px"
              />
            </label>
            <input
              id="img-preview"
              ref="photo"
              style="display: none"
              type="file"
              accept=".jpg, .jpeg, .png"
              @change="onChangePhoto($event, 'preview')"
            >
            <c-box
              mt="2rem"
              ml="1rem"
            >
              <c-button
                variant="outline"
                variant-color="primary"
                border-radius="25px"
                flex-direction="row"
                align-items="center"
                h="42px"
                mb="8px"
                font-family="Roboto"
                font-size="16px"
                @click="
                  $refs.photo.value = '';
                  $refs.photo.click();
                "
              >
                <c-box mr="8px">
                  <inline-svg
                    :src="require('@/assets/icons/icon-upload.svg')"
                    height="22px"
                    width="22px"
                    fill="#008C81"
                  />
                </c-box>
                Upload Gambar
              </c-button>
              <c-text
                px="32px"
                color="#C4C4C4"
              >
                Foto max: 2MB
              </c-text>
            </c-box>
          </c-flex>
        </c-form-control>

        <!-- Email -->
        <c-form-control
          :is-invalid="$v.email.$invalid || errors.email != ''"
          is-required
          mb="25px"
          :is-read-only="id ? true : false"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Email
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="email"
              type="email"
              error-border-color="red.300"
              placeholder="Masukkan Email"
              height="62px"
            />
          </c-input-group>
          <c-form-helper-text
            v-if="errors.email"
            font-size="14px"
            color="red.500"
          >
            {{ errors.email }}
          </c-form-helper-text>
        </c-form-control>

        <!-- STR Number -->
        <c-form-control
          mb="25px"
          is-required
          :is-invalid="$v.str.$invalid"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Nomor STR
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="str"
              type="text"
              error-border-color="red.300"
              placeholder="Masukkan Nomor STR"
              height="62px"
            />
          </c-input-group>
        </c-form-control>

        <!-- First Name -->
        <c-form-control
          mb="25px"
          is-required
          :is-invalid="$v.firstName.$invalid"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Nama Depan
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="firstName"
              type="text"
              error-border-color="red.300"
              placeholder="Masukkan Nama Depan"
              height="62px"
            />
          </c-input-group>
        </c-form-control>

        <!-- Last Name -->
        <c-form-control mb="25px">
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Nama Belakang
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="lastName"
              type="text"
              error-border-color="red.300"
              placeholder="Masukkan Nama Belakang"
              height="62px"
            />
          </c-input-group>
        </c-form-control>

        <!-- Specialization -->
        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Spesialisasi / Keahlian
        </c-heading>
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />

        <c-form-control 
          mb="25px"
          is-required
          :is-invalid="$v.specialization.$invalid"
        >
          <!-- <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Spesialisasi / Keahlian
          </c-form-label> -->
          <FormSpecialization
            :data="specialization"
            :options="SPECIALIZATIONS"
            :is-empty="isEmptySpecialization"
            :invalid="$v.specialization"
            :other-value="otherOptionValue"
            @add="addSpecialization"
            @remove="removeSpecialization"
          />
        </c-form-control>
        
        <!-- Language -->
        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Bahasa yang dikuasai
        </c-heading>
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />
        <FormLanguages
          :data="languages"
          :is-empty="isEmptyLanguages"
          @add="addLanguages"
          @remove="removeLanguages"
        />

        <!-- Client Age Handled -->
        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Usia Klien
        </c-heading>
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />
        <FormClientAgeHandled
          :data="clientAgeHandled"
          :is-empty="isEmptyClientAgeHandled"
          @add="addClientAgeHandled"
          @remove="removeClientAgeHandled"
        />

        <!-- Problem Handled -->
        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Masalah yang dikuasai
        </c-heading>
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />
        <FormProblemHandled
          :data="problemHandled"
          :is-empty="isEmptyProblemHandled"
          @add="addProblemHandled"
          @remove="removeProblemHandled"
        />

        <!-- Qouta -->
        <c-form-control
          :is-invalid="$v.quota.$invalid"
          is-required
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Kuota
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="quota"
              type="number"
              error-border-color="red.300"
              placeholder="Masukkan Quota"
              height="62px"
            />
          </c-input-group>
        </c-form-control>

        <!-- Rating -->
        <c-form-control
          :is-invalid="$v.rating.$invalid"
          is-required
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Rating
          </c-form-label>
          <c-input-group
            size="md"
            mt="10px"
          >
            <StarRating
              v-model="rating"
              :increment="0.5"
              :star-size="32"
              text-class="hidden"
            />
          </c-input-group>
        </c-form-control>

        <!-- Education -->
        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Pendidikan
        </c-heading>
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />
        <FormEducation 
          :data="education"
          :is-empty="isEmptyEducation"
          :degre="degre"
          :universities="dataUniversities"
          :invalid="$v.education"
          @add="addEducation"
          @remove="removeEducation"
          @other="(it) => onSetOtherUniversity(it.event, it.index)"
        />
    
        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Jam Layanan
        </c-heading>
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />
        <c-grid
          template-columns="repeat(2, 1fr)"
          gap="1rem"
        >
          <FormTime 
            v-model="serviceHour.start"
            :invalid="$v.serviceHour.start.$invalid"
            label="Jam Mulai Layanan"
          />
          <FormTime 
            v-model="serviceHour.end"
            :invalid="$v.serviceHour.end.$invalid"
            label="Jam Selesai Layanan"
          />
        </c-grid>

        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Jam Aktif Chat
        </c-heading>
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />
        <FormActiveHour
          :data="activeHour"
          :is-empty="isEmptyActiveHour"
          :invalid="$v.activeHour"
          @add="addActiveHour"
          @remove="removeActiveHour"
        />
        
        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Jadwal Layanan
        </c-heading>
        <!-- Active Days -->
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />
        <c-form-control
          is-required
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Hari Aktif
          </c-form-label>

          <FormSelectDays2 
            v-model="activeDays"
            :options="DAYS"
            :is-invalid="$v.activeDays.$invalid"
            :is-filter-options="true"
            :options-already-selected="offDays.concat(slowResponseDays)"
          />
          <!-- <FormSelectDays 
            :options="DAYS"
            v-model="activeDays"
            placeholder="Plih Hari"
          /> -->
        </c-form-control>

        <!-- Off Days -->
        <c-form-control
          is-required
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Hari Libur
          </c-form-label>
          <FormSelectDays2 
            v-model="offDays"
            :options="DAYS"
            :is-invalid="$v.offDays.$invalid"
            :is-filter-options="true"
            :options-already-selected="activeDays.concat(slowResponseDays)"
          />
          <!-- <FormSelectDays 
            :options="DAYS"
            v-model="offDays"
            placeholder="Plih Hari"
          /> -->
        </c-form-control>

        <!-- Slowresponse Days -->
        <c-form-control
          is-required
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Hari Slowrespon
          </c-form-label>
          <FormSelectDays2 
            v-model="slowResponseDays"
            :options="DAYS"
            :is-invalid="$v.slowResponseDays.$invalid"
            :is-filter-options="true"
            :options-already-selected="activeDays.concat(offDays)"
          />
          <!-- <FormSelectDays
            :options="DAYS"
            v-model="slowResponseDays"
            placeholder="Plih Hari"
          /> -->
        </c-form-control>

        <c-heading
          as="h4"
          font-family="Roboto"
          font-weight="bold"
          font-size="18px"
          line-height="27px"
          color="#008C81"
        >
          Pengalaman Bekerja
        </c-heading>
        <c-divider
          mt="10px"
          mb="22px"
          color="#888"
        />
        <FormExperience 
          :data="workExperiences"
          :is-empty="isEmptyworkExperiences"
          :invalid="$v.workExperiences"
          @add="addWorkExperience"
          @remove="removeWorkExperience"
        />

        <c-flex
          mt="28px"
          justify-content="space-between"
        >
          <c-button
            variant="solid"
            variant-color="gray"
            border-radius="60px"
            w="50%"
            h="62px"
            mr="20px"
            font-size="18px"
            color="#555555"
            @click="onCancel"
          >
            Batal
          </c-button>
          <c-button
            variant="solid"
            variant-color="primary"
            border-radius="60px"
            w="50%"
            h="62px"
            font-size="18px"
            :is-disabled="isDisabledSubmit"
            @click="onOpenSave"
          >
            Simpan
          </c-button>
        </c-flex>
      </form>
    </c-box>

    <!-- Delete Confirm -->
    <ModalConfirm 
      title="Anda yakin ingin hapus data ahli gizi?"
      :image="require('@/assets/icon-confirm-delete.svg')"
      :is-open="isOpenDelete"
      :is-loading="isLoading"
      submit-text="Hapus"
      @close="onCloseDelete"
      @submit="onSubmitDelete"
    />

    <!-- Save Confirm -->
    <ModalConfirm 
      :title="id ? 'Apakah anda yakin ingin melakukan perubahan data ahli gizi?' : 'Apakah anda yakin data ahli gizi sudah benar?'"
      :image="require('@/assets/icon-confirm-update.svg')"
      :is-open="isOpenSave"
      :is-loading="isLoading"
      :submit-text="id ? 'Simpan' : 'Tambah'"
      @close="onCloseSave"
      @submit="onSubmit"
    />
    
    <!-- <ModalSuccess 
      :title="titleSuccess"
      note=""
      :image="require('@/assets/paid.png')"
      :isOpen="isOpenSuccess"
      buttonText="Oke"
      @close="onCloseSuccess"
    /> -->
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import { email, required, requiredIf } from 'vuelidate/lib/validators'
// import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import StarRating from 'vue-star-rating'
// import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import FormEducation from './_widgets/form-education.vue'
import FormExperience from './_widgets/form-experience.vue'
// import FormSelectDays from './_widgets/form-select-days.vue'
import FormSelectDays2 from './_widgets/form-select-days2.vue'
import FormTime from './_widgets/form-time.vue'
import FormActiveHour from './_widgets/form-active-hours.vue'
import ModalConfirm from '@/components/widgets/modal-confirm'
// import ModalSuccess from '@/components/widgets/modal-success'
import { DAYS } from '@/constants/generals'
import FormSpecialization from './_widgets/form-specialization.vue'
import FormLanguages from './_widgets/form-languages.vue'
import FormClientAgeHandled from './_widgets/form-client-age-handled.vue'
import FormProblemHandled from './_widgets/form-problem-handled.vue'
import { SPECIALIZATION } from '@/constants/generals.js'

export default {
  name: 'ManagementNutritionistsForm',
  components: {
    // ModelListSelect,
    StarRating,
    BreadcrumbPath,
    FormEducation,
    FormExperience,
    // FormSelectDays,
    // VueTimepicker,
    FormTime,
    FormActiveHour,
    FormSelectDays2,
    ModalConfirm,
    FormSpecialization,
    FormLanguages,
    FormClientAgeHandled,
    FormProblemHandled,
    // ModalSuccess
  },
  mixins: [generalMixin],
  data() {
    return {
      errors: {
        email: '',
      },
      filePhoto: null,
      email: null,
      firstName: null,
      lastName: '',
      photoUrl: null,
      education: [
        {
          level: null,
          title: null,
          university: null,
          otherUniversity: null,
        },
      ],
      specialization: [
        {
          select: '',
          input: '',
          isOther: 'Lainnya',
        },
      ],
      str: null,
      clientAgeHandled: [''],
      languages: ['Indonesia'],
      rating: 0,
      quota: null,
      degre: [
        { value: 'd1', name: 'D1' },
        { value: 'd3', name: 'D3' },
        { value: 'd4', name: 'D4' },
        { value: 's1', name: 'S1' },
        { value: 's2', name: 'S2' },
        { value: 's3', name: 'S3' },
        { value: 'pendidikan profesi dietisien', name: 'Pendidikan Profesi Dietisien' },
      ],
      serviceHour: {
        start: '',
        end: '',
      },
      activeHour: [
        {
          startHour: '',
          endHour: '',
        },
      ],
      activeDays: [],
      offDays: [],
      slowResponseDays: [],
      workExperiences: [
        {
          position: null,
          companyName: null,
          startYear: null,
          endYear: null,
          isCurrentlyWork: false,
        },
      ],
      problemHandled: [''],
      isUploading: false,
      isLoading: false,
      isOpenDelete: false,
      isOpenSave: false,
      titleSuccess: '',
      isOpenSuccess: false,
      DAYS,
      SPECIALIZATIONS: SPECIALIZATION,
    }
  },
  computed: {
    breadcrumbPath() {
      if (this.id) {
        return [
          { label: 'Managemen Ahli Gizi', href: '/admin/nutritionists/' },
          { label: 'Detail Ahli Gizi', href: `/admin/nutritionists/${this.id}` },
          { label: 'Edit Ahli Gizi', isCurrent: true },
        ]
      }
      return [
        { label: 'Managemen Ahli Gizi', href: '/admin/nutritionists/' },
        { label: 'Tambah Ahli Gizi', isCurrent: true },
      ]
    },
    ...mapGetters({
      axios: 'axios',
      item: 'admNutritionists/item',
      universities: 'general/universities',
    }),
    dataUniversities() {
      return [
        { id: '0', name: 'Masukkan Lainnya' },
        ...this.universities.map((dt) => {
          dt.id = dt.id.toString()
          return dt
        }),
      ]
    },
    id() {
      return this.$route.params.id
    },
    isDisabledSubmit() {
      return this.$v.$invalid
    },
    otherOptionValue() {
      return this.SPECIALIZATIONS.find((it) => it?.isOther)?.value
    },
    isEmptySpecialization() {
      if (this.specialization) {
        return this.specialization.some((it) => {
          if (!it.select) {
            return true
          } else {
            return it.select == this.otherOptionValue ? !it.input : false
          }
        })
      }
      return false
    },
    isEmptyLanguages() {
      if (this.languages) {
        return this.languages.includes('')
      }
      return false
    },
    isEmptyClientAgeHandled() {
      if (this.clientAgeHandled) {
        return this.clientAgeHandled.includes('')
      }
      return false
    },
    isEmptyProblemHandled() {
      if (this.problemHandled) {
        return this.problemHandled.includes('')
      }
      return false
    },
    isEmptyworkExperiences() {
      if (this.workExperiences) {
        let emptyObj = this.workExperiences.findIndex(
          (dt) => !dt.position || !dt.companyName || (!dt.endYear && !dt?.isCurrentlyWork) || !dt.startYear,
        )
        if (emptyObj > -1) return true
      }
      return false
    },
    isEmptyEducation() {
      if (this.education) {
        let emptyObj = this.education.findIndex(
          (dt) => !dt.level || !dt.title || !dt.university,
        )
        if (emptyObj > -1) return true
      }
      return false
    },
    isEmptyActiveHour() {
      if (this.activeHour) {
        let emptyObj = this.activeHour.findIndex(
          (dt) => !dt.startHour || !dt.endHour,
        )
        if (emptyObj > -1) return true
      }
      return false
    },
    serviceTime() {
      const time = [
        {
          title: 'Hari Aktif',
          value: this.sortingDays(this.activeDays).join(', '),
        },
        {
          title: 'Hari Libur',
          value: this.sortingDays(this.offDays).join(', '),
        },
        {
          title: 'Hari Slow Response',
          value: this.sortingDays(this.slowResponseDays).join(', '),
        },
      ]

      return time
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.photoUrl = val.photoUrl
          this.email = val.email
          this.str = val.str
          this.firstName = val.firstName
          this.lastName = val.lastName
          this.specialization = val.specialization.splice(0, 3).map((it) => {
            const selected = this.SPECIALIZATIONS.find((el) => el.value == it)
            return {
              select: selected?.value || this.otherOptionValue,
              input: selected ? '' : it,
              isOther: this.otherOptionValue,
            }
          })
          this.languages = this.isArray(val?.languages) ? val.languages : val?.languages.split(',').map((it) => { return it.trim() }).filter((it) => it)
          this.clientAgeHandled = this.isArray(val?.clientAgeHandled) ? val.clientAgeHandled : val?.clientAgeHandled.split(',').map((it) => { return it.trim() }).filter((it) => it)
          this.problemHandled = val.problemHandled
          this.quota = val.quota
          this.rating = Number.isFinite(val.rating) ? val.rating : 0
          this.education = val.education.map((it) => ({
            ...it,
            university: it?.otherUniversity ? '0' : it?.university,
            otherUniversity: it?.otherUniversity ? it.otherUniversity : null,
          }))
          if (val.serviceHour) {
            const hour = val.serviceHour.split(' - ')
            this.serviceHour.start = hour[0]?.replace('.', ':')
            this.serviceHour.end = hour[1]?.replace('.', ':')
          }
          this.activeHour = val.activeHour.map((it) => {
            return {
              startHour: it.startHour?.replace('.', ':'),
              endHour: it.endHour?.replace('.', ':'),
            }
          })
          if (val.serviceTime) {
            this.activeDays = val.serviceTime.find((it) => it.title == 'Hari Aktif')?.value?.split(', ')
            this.offDays = val.serviceTime.find((it) => it.title == 'Hari Libur')?.value?.split(', ')
            
            const slowResponse = val.serviceTime.find((it) => it.title == 'Hari Slow Response')
            if (slowResponse?.value) {
              this.slowResponseDays = val.serviceTime.find((it) => it.title == 'Hari Slow Response')?.value?.split(', ')
            }
          }
          this.workExperiences = val.workExperiences.reverse().map((it) => ({
            position: it?.position,
            companyName: it?.companyName,
            startYear: it?.startYear,
            endYear: it?.endYear == 'Sekarang' ? null : it?.endYear,
            isCurrentlyWork: it?.endYear == 'Sekarang' ? true : false,
          }))
        }
      },
    },
  },
  validations: {
    email: { required, email },
    str: { required },
    firstName: { required },
    specialization: { 
      required,
      $each: { 
        select: { required },
        input: { required: requiredIf((model) => model.select == model.isOther) },
      },
    },
    quota: { required },
    rating: { required },
    education: {
      required,
      $each: {
        level: { required },
        title: { required },
        university: { required },
        otherUniversity: { required: requiredIf((model) => model.university == '0') },
      },
    },
    serviceHour: {
      start: { 
        required,
        format: (v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v),
      },
      end: { 
        required,
        format: (v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v),
      },
    },
    activeHour: {
      required,
      $each: {
        startHour: { 
          required,
          format: (v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v),
        },
        endHour: { 
          required,
          format: (v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v),
        },
      },
    },
    activeDays: {
      required,
      minLength: 1,
    },
    offDays: {
      required,
      minLength: 1,
    },
    slowResponseDays: {
      required,
      minLength: 1,
    },
    workExperiences: {
      required,
      $each: {
        position: { required },
        companyName: { required },
        startYear: { required },
        endYear: { required: requiredIf((model) => model.isCurrentlyWork == false) },
      },
    },
  },
  async mounted() {
    this.loadUniversities()
    if (!this.id) return
    await this.detailNutritionists(this.id)
    if (Object.keys(this.item).length == 0) {
      this.$toast({
        title: 'Failed',
        description: 'Ahli Gizi tidak ditemukan.',
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        variant: 'subtle',
      })
      this.$router.push({
        name: 'admin.nutritionists',
      })
    }
  },
  methods: {
    isArray(item) {
      return Array.isArray(item)
    },
    sortingDays(value) {
      if (!value || value?.length == 0) return []
      return value.sort((a, b) => {
        const orderA = this.DAYS.find(obj => obj.value === a).order
        const orderB = this.DAYS.find(obj => obj.value === b).order
        return orderA - orderB
      })  
    },
    ...mapActions({
      createNutritionists: 'admNutritionists/createNutritionists',
      detailNutritionists: 'admNutritionists/detailNutritionists',
      updateNutritionists: 'admNutritionists/updateNutritionists',
      deleteNutritionists: 'admNutritionists/deleteNutritionists',
      loadUniversities: 'general/loadUniversities',
    }),
    addSpecialization() {
      if (this.isEmptySpecialization) return
      this.specialization.push({
        select: '',
        input: '',
        isOther: this.otherOptionValue,
      })
    },
    removeSpecialization(index) {
      this.specialization.splice(index, 1)
    },
    addLanguages() {
      if (this.isEmptyLanguages) return
      this.languages.push('')
    },
    removeLanguages(index) {
      this.languages.splice(index, 1)
    },
    addClientAgeHandled() {
      if (this.isEmptyClientAgeHandled) return
      this.clientAgeHandled.push('')
    },
    removeClientAgeHandled(index) {
      this.clientAgeHandled.splice(index, 1)
    },
    addProblemHandled() {
      if (this.isEmptyProblemHandled) return
      this.problemHandled.push('')
    },
    removeProblemHandled(index) {
      this.problemHandled.splice(index, 1)
    },
    onSetOtherUniversity(e, index) {
      this.education[index].otherUniversity = e.target.value
    },
    onFileChange(input) {
      let self = this
      if (self.isUploading) return
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0]
        self.isUploading = true
        self.$toast({
          description: 'Uploading...',
          status: 'info',
          duration: null,
          position: 'top',
          variant: 'subtle',
        })
        if (file.type.includes('image') && file.size > 2097152) {
          self.isUploading = false
          return self.$toast({
            title: 'Failed',
            description: `This file can't be uploaded, because it (${self.formatBytes(
              file.size,
            )}) exceeds the maximum file size (2 MB).`,
            status: 'error',
            duration: 10000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }
        // Upload avatar to API
        let formData = new FormData()
        formData.append('file', input.target.files[0])
        for (var pair of formData.entries()) {
          console.log(pair[0] + ', ' + pair[1])
        }

        self.axios
          .post('/v1/users/upload', formData)
          .then((it) => {
            this.isUploading = false
            this.photoUrl = it.data.data.url
          })
          .catch(() => {
            self.$toast({
              title: 'Failed',
              description: 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
    },
    onChangePhoto(input) {
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0]
        if (file.type.includes('image') && file.size > 2097152) {
          return this.$toast({
            title: 'Failed',
            description: `This file can't be uploaded, because it (${this.formatBytes(
              file.size,
            )}) exceeds the maximum file size (2 MB).`,
            status: 'error',
            duration: 10000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }
        this.filePhoto = file
      }
    },
    onUploadPhoto(file) {
      let formData = new FormData()
      formData.append('file', file)

      return this.axios
        .post('/v1/users/upload', formData)
    },
    async onSubmit() {
      this.isLoading = true
      if (this.filePhoto) {
        try {
          const { data } = await this.onUploadPhoto(this.filePhoto)
          this.photoUrl = data?.data?.url
        } catch(err) {
          this.onCloseSave()
          this.$toast({
            title: 'Failed',
            description: 'Ops! Something when wrong when uploading photo.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          return
        }
      }

      this.resetErrors()
      let params = {
        photoUrl: this.photoUrl,
        email: this.email,
        str: this.str,
        firstName: this.firstName,
        lastName: this.lastName,
        specialization: this.specialization.map((it) => {
          return it.select == it.isOther ? it.input : it.select
        }),
        languages: this.languages.filter((it) => it && it !== '').join(', '),
        clientAgeHandled: this.clientAgeHandled.filter((it) => it && it !== '').join(', '),
        problemHandled: this.problemHandled ? this.problemHandled.filter((it) => it && it !== '') : null,
        quota: this.quota,
        rating: this.rating,
        education: this.education.map((it) => {
          return {
            ...it,
            otherUniversity: it.university === '0' ? it.otherUniversity : null,
          }
        }),
        serviceHour: `${this.serviceHour.start} - ${this.serviceHour.end}`,
        activeHour: this.activeHour,
        serviceTime: this.serviceTime,
        workExperiences: this.workExperiences.map((it) => {
          return {
            ...it,
            endYear: it?.isCurrentlyWork ? 'Sekarang' : it?.endYear,
          }
        }),
      }
      if (this.id) {
        params.id = this.id
        return this.updateNutritionists(params)
          .then(() => {
            this.titleSuccess = 'Data ahli gizi berhasil diubah!'
            this.isOpenSuccess = true
            this.$toast({
              title: 'Success',
              description: 'Update Ahli Gizi is successfully',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.$router.push({
              name: 'admin.nutritionists.detail',
              params: { id: this.id },
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.filePhoto = null
            this.isLoading = false
            this.onCloseSave()
          })
      }
      return this.createNutritionists(params)
        .then(() => {
          this.titleSuccess = 'Data ahli gizi berhasil ditambah!'
          this.isOpenSuccess = true
          this.$toast({
            title: 'Success',
            description: 'Create Ahli Gizi is successfully',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'admin.nutritionists',
            query: { type: this.type },
          })
        })
        .catch((err) => {
          if (err?.data?.message == 'Email Already Registered') {
            this.errors.email = 'Email sudah terdaftar.'
          }
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.filePhoto = null
          this.isLoading = false
          this.onCloseSave()
        })
    },
    onCancel() {
      if (this.id) {
        this.$router.push({
          name: 'admin.nutritionists.detail',
          params: { id: this.id },
        })
      } else {
        this.$router.push({ name: 'admin.nutritionists' })
      }
    },
    getPreviewImage(url) {
      if (this.filePhoto) {
        return URL.createObjectURL(this.filePhoto)
      }
      if (url) {
        return url
      }
      return require('@/assets/icon-photo.svg')
    },
    removeWorkExperience(index) {
      this.workExperiences.splice(index, 1)
    },
    addWorkExperience() {
      if (this.isEmptyworkExperiences) return
      this.workExperiences.push({
        position: null,
        companyName: null,
        endYear: null,
        startYear: null,
        isCurrentlyWork: false,
      })
    },
    addEducation() {
      if (this.isEmptyEducation) return
      this.education.push({
        level: null,
        title: null,
        university: null,
        otherUniversity: null,
      })
    },
    removeEducation(index) {
      this.education.splice(index, 1)
      if (this.education.length === 0) {
        this.education.push({
          level: null,
          title: null,
          university: null,
          otherUniversity: null,
        })
      }
    },
    removeActiveHour(index) {
      this.activeHour.splice(index, 1)
    },
    addActiveHour() {
      if (this.isEmptyActiveHour) return
      this.activeHour.push({
        startHour: '',
        endHour: '',
      })
    },
    onOpenSave() {
      this.isOpenSave = true
    },
    onCloseSave() {
      if (!this.isLoading) {
        this.isOpenSave = false
      }
    },
    onCloseSuccess() {
      this.isOpenSuccess = false
    },
    onOpenDelete() {
      this.isOpenDelete = true
    },
    onCloseDelete() {
      if (!this.isLoading) {
        this.isOpenDelete = false
      }
    },
    onSubmitDelete() {
      this.isLoading = true
      this.deleteNutritionists(this.id)
        .then(() => {
          this.titleSuccess = 'Data ahli gizi berhasil dihapus!'
          this.isOpenSuccess = true
          this.$toast({
            title: 'Success',
            description: 'Ahli Gizi has been deleted.',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'admin.nutritionists',
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isLoading = false
          this.onCloseDelete()
        })
    },
    resetErrors() {
      this.errors.email = ''
    },
  },
}
</script>

<style scoped>
/* Custom Select */
/* ::v-deep.ui.search.dropdown > input.search,
::v-deep.ui.selection.dropdown > .dropdown.icon,
::v-deep.ui.multiple.search.dropdown > .text {
  top: auto;
}
::v-deep.ui.dropdown .menu > .item {
  font-size: 16px;
} */

::v-deep.hidden {
  display: none;
}
::v-deep.vue__time-picker-dropdown, ::v-deep.vue__time-picker .dropdown {
  bottom: 4em !important;
}

::v-deep.vue__time-picker input.invalid {
  box-shadow:  0 0 0 1px #e66673 !important;
  border: 1px solid #e66673 !important;
}

::v-deep.vue__time-picker .controls img {
  max-width: 35px !important;
}

@media (min-width: 768px) {
  ::v-deep.vue__time-picker-dropdown, ::v-deep.vue__time-picker .dropdown {
    bottom: 4em !important;
  }
}
</style>