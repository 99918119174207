import { render, staticRenderFns } from "./form-time.vue?vue&type=template&id=1d2907f6&scoped=true&"
import script from "./form-time.vue?vue&type=script&lang=js&"
export * from "./form-time.vue?vue&type=script&lang=js&"
import style0 from "./form-time.vue?vue&type=style&index=0&id=1d2907f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d2907f6",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl})
