<template>
  <c-box>
    <c-box
      v-for="(item, index) in data"
      :key="'edu' + index"
      position="relative"
    >
      <c-form-control
        is-required
        mb="25px"
        :is-invalid="invalid?.$each[index]?.level?.$invalid"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        > 
          Degree 
        </c-form-label>
        <c-input-group size="md">
          <c-select
            v-model="item.level"
            height="62px"
            placeholder="Masukkan Level Degre"
          >
            <option
              v-for="item_ in degre"
              :key="item_.value"
              :value="item_.value"
            >
              {{ item_.name }}
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-form-control
        is-required
        mb="25px"
        :is-invalid="invalid?.$each[index]?.title?.$invalid"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        > 
          Gelar
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="item.title"
            type="text"
            placeholder="Masukkan Gelar"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        is-required
        :mb="data.length > 1 ? '12px' : '25px'"
        :is-invalid="invalid?.$each[index]?.university?.$invalid || invalid?.$each[index]?.otherUniversity?.$invalid"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Universitas
        </c-form-label>
        <ModelListSelect
          v-model="item.university"
          :list="universities"
          option-value="id"
          option-text="name"
          placeholder="Masukkan Universitas"
          style="height: 62px; display: inline-flex; align-items: center; font-size: 16px"
          :class="invalid?.$each[index]?.university?.$invalid ? 'invalid' : ''"
        />
        <c-input-group
          v-if="item.university === '0'"
          size="md"
          mt="25px"
        >
          <c-input
            v-model="item.otherUniversity"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Universitas Lainnya"
            height="62px"
            @keyup="onSetOtherUniversity($event, index)"
          />
        </c-input-group>
      </c-form-control>

      <c-box
        v-if="data.length > 1"
        mb="13px"
        text-align="right"
      >
        <c-button
          ml="18px"
          min-width="auto"
          variant-color="red"
          variant="link"
          font-size="14px"
          @click="onClickRemove(index)"
        >
          <c-image
            :src="require('@/assets/icon-trash-line.svg')"
            alt="empty"
            mr="4px"
            width="20px"
            height="20px"
          />
          Hapus Pendidikan {{ index + 1 }}
        </c-button>
      </c-box>
    </c-box>
    <c-button
      :is-disabled="isEmpty"
      variant-color="primary"
      variant="outline"
      border-radius="60px"
      h="62px"
      w="100%"
      left-icon="add"
      mb="25px"
      @click="$emit('add')"
    >
      Tambah Pendidikan
    </c-button>

    <ModalConfirm
      :title="`Anda yakin ingin hapus pendidikan ${selectedIndex+1}?`"
      :image="require('@/assets/icon-confirm-delete.svg')"
      :is-open="isOpenConfirm"
      :is-loading="false"
      submit-text="Hapus"
      @close="onCloseConfirm"
      @submit="onSubmitConfim"
    />
  </c-box>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import ModalConfirm from '@/components/widgets/modal-confirm'

export default {
  name: 'FormEducation',
  components: { ModelListSelect, ModalConfirm },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    degre: {
      type: Array,
      required: true,
      default: () => [],  
    },
    universities: {
      type: Array,
      required: true,
      default: () => [],
    },
    invalid: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpenConfirm: false,
      selectedIndex: null,
    }
  },
  emit: ['add', 'remove', 'other'],
  methods: {
    onClickRemove(index) {
      this.selectedIndex = index
      this.isOpenConfirm = true
    },
    onCloseConfirm() {
      this.isOpenConfirm = false
      this.selectedIndex = null
    },
    async onSubmitConfim() {
      await this.$emit('remove', this.selectedIndex)
      this.onCloseConfirm()
    },
    onSetOtherUniversity(e, index) {
      this.$emit('other', {
        event: e,
        index: index,  
      })
    },
  },
}
</script>

<style scoped>
::v-deep.ui.search.dropdown > input.search,
::v-deep.ui.selection.dropdown > .dropdown.icon,
::v-deep.ui.multiple.search.dropdown > .text {
  top: auto;
}
::v-deep.ui.dropdown .menu > .item {
  font-size: 18px;
}
::v-deep.hidden {
  display: none;
}
::v-deep.ui.selection.dropdown.invalid {
  box-shadow:  0 0 0 1px #e66673 !important;
  border: 1px solid #e66673 !important;
}
</style>
