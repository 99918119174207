var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm._l(_vm.data, function (item, index) {
    var _vm$invalid, _vm$invalid$$each$ind, _vm$invalid$$each$ind2, _vm$invalid2, _vm$invalid2$$each$in, _vm$invalid2$$each$in2, _vm$invalid3, _vm$invalid3$$each$in, _vm$invalid3$$each$in2, _vm$invalid4, _vm$invalid4$$each$in, _vm$invalid4$$each$in2;

    return _c('c-box', {
      key: index,
      attrs: {
        "position": "relative"
      }
    }, [_c('c-form-control', {
      attrs: {
        "is-required": "",
        "mb": "25px",
        "is-invalid": (_vm$invalid = _vm.invalid) === null || _vm$invalid === void 0 ? void 0 : (_vm$invalid$$each$ind = _vm$invalid.$each[index]) === null || _vm$invalid$$each$ind === void 0 ? void 0 : (_vm$invalid$$each$ind2 = _vm$invalid$$each$ind.position) === null || _vm$invalid$$each$ind2 === void 0 ? void 0 : _vm$invalid$$each$ind2.$invalid
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Posisi Bekerja " + _vm._s(index + 1) + " ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Posisi Bekerja",
        "height": "62px"
      },
      model: {
        value: item.position,
        callback: function callback($$v) {
          _vm.$set(item, "position", $$v);
        },
        expression: "item.position"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "is-required": "",
        "mb": "25px",
        "is-invalid": (_vm$invalid2 = _vm.invalid) === null || _vm$invalid2 === void 0 ? void 0 : (_vm$invalid2$$each$in = _vm$invalid2.$each[index]) === null || _vm$invalid2$$each$in === void 0 ? void 0 : (_vm$invalid2$$each$in2 = _vm$invalid2$$each$in.companyName) === null || _vm$invalid2$$each$in2 === void 0 ? void 0 : _vm$invalid2$$each$in2.$invalid
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Nama Perusahaan ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Pengalaman Bekerja",
        "height": "62px"
      },
      model: {
        value: item.companyName,
        callback: function callback($$v) {
          _vm.$set(item, "companyName", $$v);
        },
        expression: "item.companyName"
      }
    })], 1)], 1), _c('c-flex', {
      attrs: {
        "align": "center"
      }
    }, [_c('c-form-control', {
      style: {
        width: '50%',
        marginRight: '20px'
      },
      attrs: {
        "is-required": "",
        "mb": "12px",
        "is-invalid": (_vm$invalid3 = _vm.invalid) === null || _vm$invalid3 === void 0 ? void 0 : (_vm$invalid3$$each$in = _vm$invalid3.$each[index]) === null || _vm$invalid3$$each$in === void 0 ? void 0 : (_vm$invalid3$$each$in2 = _vm$invalid3$$each$in.startYear) === null || _vm$invalid3$$each$in2 === void 0 ? void 0 : _vm$invalid3$$each$in2.$invalid
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Tahun Masuk ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "number",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Tahun Masuk",
        "height": "62px"
      },
      model: {
        value: item.startYear,
        callback: function callback($$v) {
          _vm.$set(item, "startYear", $$v);
        },
        expression: "item.startYear"
      }
    })], 1)], 1), _c('c-form-control', {
      style: {
        width: '50%'
      },
      attrs: {
        "is-required": "",
        "mb": "12px",
        "is-disabled": item.isCurrentlyWork,
        "is-invalid": (_vm$invalid4 = _vm.invalid) === null || _vm$invalid4 === void 0 ? void 0 : (_vm$invalid4$$each$in = _vm$invalid4.$each[index]) === null || _vm$invalid4$$each$in === void 0 ? void 0 : (_vm$invalid4$$each$in2 = _vm$invalid4$$each$in.endYear) === null || _vm$invalid4$$each$in2 === void 0 ? void 0 : _vm$invalid4$$each$in2.$invalid
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Tahun Keluar ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "number",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Tahun Keluar",
        "height": "62px"
      },
      model: {
        value: item.endYear,
        callback: function callback($$v) {
          _vm.$set(item, "endYear", $$v);
        },
        expression: "item.endYear"
      }
    })], 1)], 1)], 1), _c('c-flex', {
      attrs: {
        "justify": "space-between",
        "align": "center",
        "mb": "25px",
        "mx": "8px"
      }
    }, [_c('c-flex', {
      attrs: {
        "align": "center"
      }
    }, [_c('c-checkbox', {
      attrs: {
        "size": "md",
        "variant-color": "primary"
      },
      model: {
        value: item.isCurrentlyWork,
        callback: function callback($$v) {
          _vm.$set(item, "isCurrentlyWork", $$v);
        },
        expression: "item.isCurrentlyWork"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "14px",
        "color": "#888"
      }
    }, [_vm._v(" Saya masih bekerja di sini ")])], 1)], 1), _vm.data.length > 1 ? _c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link",
        "font-size": "14px"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickRemove(index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty",
        "mr": "4px",
        "width": "20px",
        "height": "20px"
      }
    }), _vm._v(" Hapus Pengalaman " + _vm._s(index + 1) + " ")], 1) : _vm._e()], 1)], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.isEmpty,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add');
      }
    }
  }, [_vm._v(" Tambah Pengalaman Kerja ")]), _c('ModalConfirm', {
    attrs: {
      "title": "Anda yakin ingin hapus pengalaman bekerja ".concat(_vm.selectedIndex + 1, "?"),
      "image": require('@/assets/icon-confirm-delete.svg'),
      "is-open": _vm.isOpenConfirm,
      "is-loading": false,
      "submit-text": "Hapus"
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": _vm.onSubmitConfim
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }